<template>
  <div>
    <div class="d-flex flex-column flex-md-row  rounded bg-white p-3 gap-2" :class="{ 'border border-primary': showMore }"
      :id="`mylisting-card-${carDetail.ListingId}`">
      <div class="col-12 col-md-3-custom p-0">
        <b-img
            :src="getImageUrl(carDetail.Image)"
            blank-color="black"
            class="rounded listing-image"
            fluid-grow
          />
      </div>
      <div class="w-100 d-flex flex-column gap-1">
        <div class="text-black d-flex align-items-center text-right justify-content-between gap-2">
          <div>
            <h4 class="d-inline-block car-name mb-0" style="font-weight:600">
              {{ carDetail.Year }}
              {{ carDetail.Make }}
              {{ carDetail.Model }}
            </h4> 
            &nbsp;
            {{ carDetail.Trim }}
          </div>
          <div v-if="detailType == 'listings'">
                <!-- <b-button
                    size="sm"
                    class="bg-white border-0"
                    @click="getCarInfo(carDetail.ListingId)"
                    ><b-icon icon="pencil-square" variant="primary"></b-icon
                  ></b-button>
                  | -->
                <b-button
                  size="sm"
                  class="bg-white border-0"
                  @click="$bvModal.show('confirmListingCancel' + carDetail.ListingId)"
                  ><b-icon icon="trash"/></b-button>
              </div>
        </div>
        <div>
          <h4 v-if="carDetail.Price" class="price text-primary mb-0" style="font-weight:600">
              {{ currencyFormatter(carDetail.Price.Amount ? carDetail.Price.Amount : 0) }}
            </h4>
            <h4 v-else>&nbsp;</h4>
        </div>
        <div class="d-flex">
          <div class="col-8 col-md-6 p-0">
            <p class="car-details">
              <span v-if="carDetail.Transmission"> {{ carDetail.Transmission }}</span>
              <span v-if="carDetail.FuelType">
                <span class="divider-vertical"/>
                {{ carDetail.FuelType }}
              </span>
              <span v-if="carDetail.Kilometers">
                <span class="divider-vertical"/>
                {{ carDetail.Kilometers ? carDetail.Kilometers : 0 }}
              </span>
            </p>
            <p class="car-details">
              <span>
                {{ carDetail.Country }}
              </span>
              <span v-if="carDetail.City">
                <span class="divider-vertical"/> {{ carDetail.City }}
              </span>
            </p>
            <p class="car-details">
              {{$t("dashboard.label.reference")}}:&nbsp;<span>{{ carDetail.ListingRef }}</span>
            </p>
          </div>
          <div class="listing-details col-4 col-md-6 p-0">
            <section v-if="detailType == 'pending'">
              <p>
                {{$t("dashboard.label.listed")}}:
                <span v-if="carDetail.DateListed">
                  {{
                    carDetail.DateListed.includes("T")
                      ? carDetail.DateListed.split("T")[0]
                      : carDetail.DateListed
                  }}
                </span>
              </p>
              <p>
                {{$t("dashboard.label.reference")}}: <span>{{ carDetail.ListingRef }}</span>
              </p>
              <p>
                {{$t("dashboard.label.sold")}}:
                <span v-if="carDetail.DateOfLastActivity">
                  {{
                    carDetail.DateOfLastActivity.includes("T")
                      ? carDetail.DateOfLastActivity.split("T")[0]
                      : carDetail.DateOfLastActivity
                  }}
                </span>
              </p>
              <p>
                {{$t("dashboard.label.status")}}:
                <span class="text-primary"
                  >{{ carDetail.Status }}
                  <b-icon :id="carDetail.ListingId" icon="info-circle"/>
                </span>
              </p>
            </section>
            <section v-if="detailType == 'confirmed'">
              <p>
                {{$t("dashboard.label.listed")}}:
                <span v-if="carDetail.DateListed">{{
                  carDetail.DateListed.includes("T")
                    ? carDetail.DateListed.split("T")[0]
                    : carDetail.DateListed
                }}</span>
              </p>
              <p>
                {{$t("dashboard.label.activity")}}:
                <span v-if="carDetail.DateOfLastActivity">{{
                  carDetail.DateOfLastActivity.includes("T")
                    ? carDetail.DateOfLastActivity.split("T")[0]
                    : carDetail.DateOfLastActivity
                }}</span>
              </p>
              <p>
                {{$t("dashboard.label.status")}}:
                <span class="text-primary"
                  >{{ carDetail.Status }}
                  <b-icon :id="carDetail.ListingId" icon="info-circle" />
                  <!-- <b-tooltip
                  :target="carDetail.l_ListingId"
                  triggers="hover"
                  placement="bottom"
                  variant="dark"
                  ><span style="font-size:10px;">Status</span></b-tooltip
                >--></span
                >
              </p>
            </section>

            <section v-if="detailType == 'listings'">
              <p>
                {{$t("dashboard.label.listed")}}:
                <span v-if="carDetail.DateListed">{{
                  carDetail.DateListed.includes("T")
                    ? carDetail.DateListed.split("T")[0]
                    : carDetail.DateListed
                }}</span>
              </p>
              <p>
                {{$t("dashboard.label.daysOnline")}}: <span>{{ carDetail.NumberOfDaysOnline }}</span>
              </p>
              <p>
                {{$t("ListingProcess.plan")}}: <span>{{ carDetail.Plan }}</span>
              </p>
            </section>
          </div>
        </div>
        <div class="d-flex flex-md-row-custom flex-column justify-content-between align-items-end gap-2">
          <b-alert show class="listing-alert col-md-6-custom">
              <p class="item"><span>{{$t("dashboard.label.status")}}:</span> {{ carDetail.Status }}</p>
              <p class="item">
                <span>{{$t("dashboard.label.inspection")}}:</span> {{ carDetail.InspectionStatus }}
              </p>
            </b-alert>
            
            <div class="button-wrapper align-items-center d-flex" style="height: fit-content;">
            <!-- <span class="mini-font">
                Price
                <b-icon
                  icon="info-circle"
                  :id="carDetail.ListingId + 'price'"
                ></b-icon>
                <b-tooltip
                  :target="carDetail.ListingId + 'price'"
                  triggers="hover"
                  placement="bottom"
                  variant="dark"
                  ><span style="font-size:10px;">Price</span></b-tooltip
                >
              </span> -->
            <div>
              <b-button
                v-if="detailType == 'pending'"
                class="py-0"
                style="font-size: 11px"
                size="sm"
                variant="danger"
                pill
              >
                <b-icon icon="exclamation-circle"/>
                {{$t("dashboard.label.vehicleNoLongerAvailable")}}
              </b-button>
              <h2
                v-if="detailType == 'confirmed'"
                class="text-danger m-0 font-weight-bold"
              >
                {{$t("dashboard.label.sold")}}!
              </h2>
            </div>
            <b-button
              v-if="showMore"
              @click="closeDetails()"
              variant="outline-primary"
              class="show-button rounded-pill flex-grow-1  py-2 px-8"
            >
              {{$t("dashboard.label.ShowLess")}}<b-icon icon="arrow-up"/>
            </b-button>
            <b-button
              v-else
              @click="getCarInfo(carDetail.ListingId)"
              variant="outline-primary"
              class="show-button rounded-pill flex-grow-1  py-2 px-8"
              >{{$t("dashboard.label.ShowMore")}} <b-icon icon="arrow-down" v-show="!loading"/>
              <b-icon
                icon="arrow-clockwise"
                animation="spin"
                font-scale="1"
                v-show="loading"
              />
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showMore" class="review-details">
      <TheReviewDetail
        :carInfo="carinfo"
        :carDetail="carDetail"
        :price="carDetail.Price.Amount"
        :mileage="carDetail.Kilometers"
        :listingId="carDetail.ListingId"
        :getCarInfo="getCarInfo"
        :key="componentKey"
      />
      <div class="text-center">
        <b-button @click="closeDetails()" variant="link" class="px-3 closeDetailsIcon">{{$t("dashboard.label.close")}}</b-button>
      </div>
    </div>
    <b-modal
      :id="'confirmListingCancel' + carDetail.ListingId"
      title="Listing Cancellation"
      hide-footer
      centered
    >
      <template #default="{ hide }">
        <p class="m-3">{{$t("dashboard.label.cancelListingMessage")}}</p>
        <b-row>
          <b-col class="m-3">
            <b-img
              :src="getImageUrl(carDetail.Image)"
              blank-color="black"
              class="carImg"
              fluid-grow
            />
          </b-col>
          <b-col class="m-3">
            <p>{{ carDetail.Year }}</p>
            <p>{{ carDetail.Make }} {{ carDetail.Model }}</p>
            <p>{{ carDetail.Trim }}</p>
          </b-col>
        </b-row>
        <b-row class="m-2">
          <b-col>
            <b-button variant="secondary" block @click="hide()">{{$t("dashboard.label.NoForgetIt")}}</b-button></b-col>
          <b-col>
            <b-button
              variant="danger"
              block
              @click="cencalVehicle(carDetail.ListingId), hide()"
              >{{$t("dashboard.label.YesCancelIt")}}</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- {{ carDetail }} -->
  </div>
</template>

<script>
import axios from "axios";
// import TheOverViewDetail from "@/components/global/TheOverViewDetail.vue";
import TheReviewDetail from "@/components/global/TheListingReviewCard.vue";

export default {
  props: {
    carDetail: {
      type: Object,
      required: true,
    },
    detailType: {
      type: String,
      required: true,
    },
  },
  components: {
    // TheOverViewDetail,
    TheReviewDetail,
  },
  data() {
    return {
      slide: 0,
      showMore: false,
      loading: false,
      carinfo: null,
      componentKey: 0,
    };
  },
  methods: {
    scrollListing(id= false){
      const el = document.querySelector(`#mylisting-card-${id ? id : this.carDetail.ListingId}`);
      if(el) el.scrollIntoView();
    },
    scrollListingDetails(id= false){
      const el = document.querySelector(`#mylisting-card-${id ? id : this.carDetail.ListingId}`);
      if(el && el.nextSibling) el.nextSibling.scrollIntoView();
    },
    closeDetails(){
      this.scrollListing();
      this.showMore = false;
    },
    currencyFormatter(value) {
      const sc = this.$store.getters.currencyForSeller;
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: sc ? sc : "CAD",
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
    numberFormatter(value) {
      return parseFloat(value ? value : 0).toLocaleString(
        this.$store.getters.currencyCulture,
        {
          style: "unit",
          unit: "kilometer",
        }
      );
    },
    getImageUrl(imageUrl) {
      return this.$store.getters.hostURL + imageUrl;
    },
    async getCarInfo(id) {
      const el = document.querySelector('.closeDetailsIcon');
      if(el) el.click()
      this.loading = true;
      try {
        const response = await axios.post("Listing/CarInfo", {
          ListingId: id,
        });
        this.carinfo = response.data;
        this.forceRerender();
        this.showMore = true;
        this.loading = false;
        this.$nextTick(() => {
          this.scrollListingDetails(id);
        })
      } catch (error) {
        this.loading = false;
        console.log(error.data);
      }
    },
    async cencalVehicle(id) {
      try {
        await axios.post("Listing/ListingCancel", {
          ListingId: id,
        });
        this.reload();
      } catch (error) {
        console.log(error.response);
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    reload() {
      this.$emit("qDateFilter", 4, []);
    },
  },
  mounted() {
    this.$store.dispatch('setDashboardType', 'SellerDashboard')
  },
};
</script>

<style scoped lang="scss">
    .car-details {
      font-weight: 400;
      font-size: 14px;
      color: #4b4b4b;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    
    span {
      display: inline-flex;
      align-items: center;
    }
    }
    .divider-vertical {
      height: 16px;
      background: #777777;
      width: 1px;
      margin: 0 8px;
      display: inline-block;
    }

    .listing-alert {
      border-radius: 4px;
      background: #e6f0f9;
      padding: 16px;
      border: none;
      min-height: 88px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0;
      width: 100%;

      @media screen and (min-width:960px) {
        width: 390px;
      }
      .item {
        font-weight: 500;
        color: #111;
        margin-bottom: 0;
        font-size: 16px;
        span {
          color: #777777;
          font-weight: 400;
        }
      }
    }
    
    .listing-details {
    p {
      margin-bottom: 8px;
      font-size: 14px;
      color: #777;

      span {
        font-size: 14px;
        color: #111111;
      }
    }
  }

  .d-md-flex-custom {
    @media (min-width: 768px) {
      display: block!important;
    }
    @media (min-width: 960px) {
      display: flex!important;
    }
}
  .flex-md-row-custom {
    @media (min-width: 768px) {
      flex-direction: column !important;
    }
    @media (min-width: 960px) {
      flex-direction: row !important;
    }
}
.col-md-3-custom{
  @media (min-width: 960px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
}

.col-md-6-custom {
  @media screen and (min-width:960px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.button-wrapper {
  width:100%;
  @media screen and (min-width: 960px) {
    width:fit-content;
  }
}

.listing-image {
  height:250px;
  @media screen and (min-width:600px) {
    height:300px;
  }
  @media screen and (min-width:668px) {
    height:325px;
  }
  @media screen and (min-width:730px) {
    height:350px;
  }
  @media screen and (min-width:960px) {
    height:100%;
  }
  object-fit:cover;
  object-position: right;
}

.show-button {
    height: 48px;
    border-radius: 40px;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 500;
    font-family: "Inter" !important;
    border: 1px solid $light-blue;
    background: white;
    color: $light-blue !important;
    min-width: 188px;
    text-decoration: none;
    &:active,
    &:hover {
      background: $light-blue;
      color: #fff !important;
    }
    svg {
      font-size: 102% !important;
      margin-left: 8px;
    }
  }
.review-details {
  border: 1px solid $light-blue;
  padding: 40px;
  border-radius: 4px;
  background: white;
  margin-bottom: 24px;
  margin-top: 24px;
}
</style>
