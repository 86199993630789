<template>
  <b-row align-h="end" class="pt-6 pb-4">
    <b-col class="d-flex justify-content-start" v-if="showFilters">
      <div v-for="(qStatus, key) in qStatusArray" :key="key" class="my-auto">
        <!-- @click="
            key === 0
              ? (qStatusFilter = [])
              : qStatusFilter.includes(qStatus.Value)
              ? (removeFilters(qStatus.Value),
                swapPositions(key, qStatus.Order))
              : (addToFilters(qStatus.Value), swapPositions(key, 1)),
              qFilter()
          "         -->
        <b-button
          @click="
            key !== 0
              ? qStatusFilter.includes(qStatus.Value)
                ? removeFilters(qStatus.Value)
                : addToFilters(qStatus.Value)
              : (qStatusFilter = [-1]),
              qFilter()
          "
          :class="qStatusFilter.includes(qStatus.Value) ? 'active' : ''"
          class="text-nowrap titlebar-btn"
          >{{ qStatus.Name }}</b-button
        >
      </div>
    </b-col>

    <b-col
      cols="auto"
      class="d-flex justify-content-start listing-filter align-items-center"
    >
      <h6 class="name" v-if="showCount">
        {{$t("dashboard.label.vehicles")}}: <span class="text-primary value">{{ totalCount }}</span>
      </h6>
      <div class="vertical-divier"></div>
      <h6 class="name" v-if="showValue">
        {{$t("dashboard.label.totalValue")}}:
        <span class="text-primary value">{{ totalValue || 0 }}</span>
      </h6>

      <div>
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          id="qTipFilter"
          class="listing-filter-btn"
        >
          <template #button-content>
            <span class="h3">
              <b-icon
                v-if="!qDateFilter && qStatusFilter.length === 0"
                icon="filter"
                variant="dark"
                class="p-1"
              />
              <b-icon v-else icon="filter" variant="dark" class="rounded p-1"></b-icon>
            </span>
            <b-tooltip target="qTipFilter" triggers="hover">
              {{ qFilterText }}
            </b-tooltip>
          </template>
          <b-dropdown-header variant="primary" class="h3 mt-n2 rounded-top">{{
            $t("search.label.date")
          }}</b-dropdown-header>
          <b-dropdown-item
            v-for="(qDate, key) in qDateCodes"
            :key="key"
            @click="key === 0 ? (qDateFilter = null) : (qDateFilter = key), qFilter()"
            :class="qDateFilter === key ? 'bg-warning' : ''"
            >{{ qDate.description }}</b-dropdown-item
          >
          <!-- <b-dropdown-item
            @click="(qDateFilter = null), qFilter()"
            :class="!qDateFilter ? 'bg-warning' : ''"
            >{{ $t("search.label.alldates") }}</b-dropdown-item
          >
          <b-dropdown-item
            @click="(qDateFilter = 1), qFilter()"
            :class="qDateFilter === 1 ? 'bg-warning' : ''"
            @
            >{{ $t("search.label.30days") }}</b-dropdown-item
          >
          <b-dropdown-item
            @click="(qDateFilter = 2), qFilter()"
            :class="qDateFilter === 2 ? 'bg-warning' : ''"
            >{{ $t("search.label.90days") }}</b-dropdown-item
          >
          <b-dropdown-item
            @click="(qDateFilter = 3), qFilter()"
            :class="qDateFilter === 3 ? 'bg-warning' : ''"
            >{{ $t("search.label.365days") }}</b-dropdown-item
          > -->

          <!-- <b-dropdown-header variant="primary" class="h3 bg-secondary mt-4">{{
          $t("search.label.status")
        }}</b-dropdown-header>
        <b-dropdown-item
          v-for="qStatus in qStatusCodes"
          :key="qStatus.value"
          @click="(qStatusFilter = qStatus.value), qFilter()"
          :class="qStatusFilter === qStatus.value ? 'bg-warning' : ''"
          >{{ qStatus.description }}</b-dropdown-item
        > -->
        </b-dropdown>
        <!-- <b-button
          id="dashboard_filter"
          :variant="
            !qDateFilter && qStatusFilter.length === 0
              ? 'transparent'
              : 'secondary'
          "
          class="p-1 mx-3"
        >
          <b-icon-sliders
            class="p-1"
            font-scale="1.5"
            :variant="
              !qDateFilter && qStatusFilter.length === 0 ? 'black' : 'primary'
            "
          />
        </b-button>

        <b-popover
          target="dashboard_filter"
          triggers="click"
          :show.sync="popoverShow"
          placement="bottomleft"
          ref="popover"
        >
          <template #title>
            <b-button @click="onClose" class="close">
              <b-icon-x />
            </b-button>
            filters
          </template>

          <b-card-header header-bg-variant="white">
            {{ $t("search.label.date") }}
          </b-card-header>
          <b-card-body>
            <b-button
              v-for="qDate in qDateCodes"
              :key="qDate.value"
              pill
              size="sm"
              :variant="
                qDateFilter === qDate.value ? 'primary' : 'outline-primary'
              "
              @click="qDateFilter = qDate.value"
              class="mx-2 my-1"
              >{{ qDate.description }}</b-button
            >

            <p>qDateFilter: {{ qDateFilter }}</p>
          </b-card-body>

          <b-card-header header-bg-variant="white">
            {{ $t("search.label.status") }}
          </b-card-header>
          <b-card-body>
            <b-button
              v-for="qStatus in qStatusCodes"
              :key="qStatus.value"
              @click="addToFilters(qStatus.value)"
              pill
              size="sm"
              :variant="
                qStatusFilter === qStatus.value ? 'primary' : 'outline-primary'
              "
              class="mx-2 my-1"
              >{{ qStatus.description }}</b-button
            >
            <p>qStatusFilter: {{ qStatusFilter }}</p>
            <b-form-checkbox-group v-model="qStatusFilter">
              <b-form-checkbox
                v-for="qStatus in qStatusCodes"
                :key="qStatus.value"
                :value="qStatus.value"
                class="rounded "
                >{{ qStatus.description }}</b-form-checkbox
              >
            </b-form-checkbox-group>
          </b-card-body>

          <div class="float-right m-2">
            <b-button
              @click="(qDateFilter = null), (qStatusFilter = []), qFilter()"
              size="sm"
              variant="outline-danger"
              class="mx-2"
              >Clear</b-button
            >
            <b-button
              @click="onClose(), qFilter()"
              size="sm"
              variant="primary"
              class="mx-2"
              >Ok</b-button
            >
          </div>
        </b-popover> -->
      </div>

      <div v-if="showSort">
        <b-dropdown
          right
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          id="qTipSort"
          class="listing-filter-btn"
        >
          <template #button-content>
            <span class="h3">
              <b-icon
                v-show="qSortOrder === 0"
                icon="sort-down"
                variant="dark"
                class="p-1"
              />
              <b-icon
                v-if="qSortOrder > 0 && qSortIcon === 0"
                icon="sort-down"
                variant="dark"
                class="rounded p-1"
              />
              <b-icon
                v-if="qSortOrder > 0 && qSortIcon === 1"
                icon="sort-up"
                variant="dark"
                class="rounded p-1"
              />
            </span>
            <b-tooltip target="qTipSort" triggers="hover">
              {{ qSortText }}
            </b-tooltip>
          </template>
          <b-dropdown-item
            @click="
              (qSortOrder = 6),
                (qCurrentSort = 'search.label.newOld'),
                (qSortIcon = 0),
                qSort()
            "
            :class="qSortOrder === 6 ? 'bg-warning' : ''"
            >{{ $t("search.label.newOld") }}</b-dropdown-item
          >
          <b-dropdown-item
            @click="
              (qSortOrder = 5),
                (qCurrentSort = 'search.label.oldNew'),
                (qSortIcon = 1),
                qSort()
            "
            :class="qSortOrder === 5 ? 'bg-warning' : ''"
            >{{ $t("search.label.oldNew") }}</b-dropdown-item
          >

          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            @click="
              (qSortOrder = 1),
                (qCurrentSort = 'search.label.priceLowHigh'),
                (qSortIcon = 1),
                qSort()
            "
            :class="qSortOrder === 1 ? 'bg-warning' : ''"
            >{{ $t("search.label.priceLowHigh") }}</b-dropdown-item
          >
          <b-dropdown-item
            @click="
              (qSortOrder = 2),
                (qCurrentSort = 'search.label.priceHighLow'),
                (qSortIcon = 0),
                qSort()
            "
            :class="qSortOrder === 2 ? 'bg-warning' : ''"
            >{{ $t("search.label.priceHighLow") }}</b-dropdown-item
          >
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            @click="
              (qSortOrder = 3),
                (qCurrentSort = 'search.label.mileLowHigh'),
                (qSortIcon = 1),
                qSort()
            "
            :class="qSortOrder === 3 ? 'bg-warning' : ''"
            >{{ $t("search.label.mileLowHigh") }}</b-dropdown-item
          >
          <b-dropdown-item
            @click="
              (qSortOrder = 4),
                (qCurrentSort = 'search.label.mileHighLow'),
                (qSortIcon = 0),
                qSort()
            "
            :class="qSortOrder === 4 ? 'bg-warning' : ''"
            >{{ $t("search.label.mileHighLow") }}</b-dropdown-item
          >
        </b-dropdown>
      </div>

      <div v-if="showSellButton">
        <TheSellButton />
      </div>
    </b-col>
  </b-row>
</template>
<script>
import TheSellButton from "@/components/dashboard/global/TheSellAVehicleButton.vue";

export default {
  components: {
    TheSellButton,
  },

  data() {
    return {
      qSortOrder: 0,
      qSortText: this.$t("search.label.sort"),
      qCurrentSort: "",
      qSortIcon: 0,
      qFilterText: this.$t("search.label.filters"),
      qDateFilter: null,
      qStatusFilter: [-1],
      qStatusArray: [],
      popoverShow: false,
      filterLimit: 3,
    };
  },
  computed: {
    qStatusCodes() {
      if (this.sectionID == "buyerReservations" && this.$store.getters.reservationList)
        return this.$store.getters.reservationList.StatusCodes;
      if (this.sectionID == "buyerOrders" && this.$store.getters.orderList)
        return this.$store.getters.orderList.StatusCodes;
      if (this.sectionID == "sellerPending" && this.$store.getters.pendingSalesList)
        return this.$store.getters.pendingSalesList.StatusCodes;
      if (this.sectionID == "sellerConfirmed" && this.$store.getters.confirmedSalesList)
        return this.$store.getters.confirmedSalesList.StatusCodes;
      if (this.sectionID == "sellerArchived" && this.$store.getters.archivedList) {
        let s = this.$store.getters.archivedList.StatusCodes;
        s = s.filter(
          (statusCode) =>
            statusCode.Category === "All" || statusCode.Category === "Archived"
        );
        return s.sort((a, b) => (a.Order > b.Order ? 1 : -1));
      }
      if (this.sectionID == "sellerListings" && this.$store.getters.myListingsList) {
        let s = this.$store.getters.myListingsList.StatusCodes;
        s = s.filter(
          (statusCode) =>
            statusCode.Category === "All" || statusCode.Category === "ToSell"
        );
        return s.sort((a, b) => (a.Order > b.Order ? 1 : -1));
      }
      return null;
    },
    qDateCodes() {
      if (this.sectionID == "buyerReservations" && this.$store.getters.reservationList)
        return this.$store.getters.reservationList.Date;
      if (this.sectionID == "buyerOrders" && this.$store.getters.orderList)
        return this.$store.getters.orderList.Date;
      if (this.sectionID == "sellerPending" && this.$store.getters.pendingSalesList)
        return this.$store.getters.pendingSalesList.Date;
      if (this.sectionID == "sellerConfirmed" && this.$store.getters.confirmedSalesList)
        return this.$store.getters.confirmedSalesList.Date;
      if (this.sectionID == "sellerListings" && this.$store.getters.myListingsList) {
        const d = this.$store.getters.myListingsList.Date;
        return d.sort((a, b) => (a.Order > b.Order ? 1 : -1));
      }
      if (this.sectionID == "sellerArchived" && this.$store.getters.archivedList) {
        const d = this.$store.getters.archivedList.Date;
        return d.sort((a, b) => (a.Order > b.Order ? 1 : -1));
      }

      return null;
    },
  },
  beforeMount() {
    this.qStatusArray = this.qStatusCodes;
  },
  updated() {
    this.qStatusArray = this.qStatusCodes;
  },
  name: "TheTitleBar",
  methods: {
    qSort() {
      this.qSortText = this.$t("search.label.sort") + ": " + this.$t(this.qCurrentSort);
      this.$emit("qSortValue", this.qSortOrder);
    },
    qFilter() {
      this.$emit("qDateFilter", this.qDateFilter, this.qStatusFilter);
    },
    addToFilters(add) {
      this.qStatusFilter = this.qStatusFilter.filter((e) => e !== -1);
      this.qStatusFilter.push(add);
    },
    removeFilters(value) {
      if (this.qStatusFilter.length === 1) {
        this.qStatusFilter = [];
      } else {
        let array = [];
        array = this.qStatusFilter;
        this.qStatusFilter = array.filter(function (ele) {
          return ele !== value;
        });
      }
    },
    swapPositions(from, to) {
      this.qStatusArray = [...this.qStatusArray];
      const item = this.qStatusArray.splice(from, 1)[0];
      this.qStatusArray.splice(to, 0, item);
    },
    onClose() {
      this.popoverShow = false;
    },
  },

  props: {
    sectionTitle: {
      type: String,
      required: true,
      default: "dashboard.label.mnuDashboard",
    },
    sectionID: {
      type: String,
      required: true,
      default: "dashHome",
    },
    showCount: {
      type: Boolean,
      required: true,
      default: false,
    },
    showValue: {
      type: Boolean,
      required: true,
      default: false,
    },
    totalCount: {
      type: Number,
      required: false,
      default: 0,
    },
    totalValue: {
      required: false,
      default: "0",
    },
    showFilters: {
      type: Boolean,
      required: true,
      default: false,
    },
    showSort: {
      type: Boolean,
      required: true,
      default: false,
    },
    showSellButton: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.popover {
  min-width: 400px;
}
.titlebar-btn {
  height: 32px;
  padding: 0 16px !important;
  font-size: 16px !important;
  font-family: "Inter", sans-serif !important;
  background: #d0d0d0;
  color: #232323 !important;
  margin-right: 16px;
  border-radius: 27px !important;
  &.active {
    background: #111111 !important;
    color: white !important;
  }
}
.listing-filter {
  .name {
    font-size: 16px;
    font-weight: 400;
    font-family: "Inter", sans-serif !important;
    margin-bottom: 0;
  }
  .value {
    font-size: 16px;
    font-weight: 500;
    font-family: "Inter", sans-serif !important;
  }
  .vertical-divier {
    height: 16px;
    background: #777777;
    width: 1px;
    margin: 0 16px;
  }
  &-btn {
    margin-left: 24px;
    button {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background: white;
      padding: 0;
    }
  }
}
</style>
